<template>
    <section id="la-chouette-agence" class="la-chouette-agence">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'la-chouette-agence/paralax/access',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 1.5,
                alt: 'Exemple d\'optimisation d\'accessibilité'
            },
            {
                image: 'la-chouette-agence/paralax/seo',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'top',
                vitesse: 1.5,
                alt: 'Exemple d\'optimisation SEO'
            },
            {
                image: 'la-chouette-agence/paralax/perform',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 1.5,
                alt: 'Exemple d\'optimisation de performance'
            },
        ]"
        :rotate="25"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="`La fondatrice de la Chouette Agence cherchait une solution pour faire repartir l’activité de l\'entreprise qui était à ce moment-là en baisse. 
        En effet, un jour, en tapant \'Entreprise web design Lyon\' sur Internet, elle s\'était aperçu que le site de l’agence apparaît seulement en deuxième page des moteurs de recherche.`"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'la-chouette-agence/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à indiquer les problèmes d'accessibilité, de SEO et de performance du site web en créant en rapport d'analyse.
        Ensuite, je devais apporter des modifications au code afin de corriger les différents problèmes, puis rendre un rapport d'optimisation une fois les modifications émises.`"
        />

        <MediaComment
        :params="{
            media: 'la-chouette-agence/image/performance',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Performance'"
        :text="`Dans un premier temps, en optimisant les images et en différant le chargement des scripts qui bloquaient le rendu, les performances ont nettement augmenté.`"
        />

        <MediaComment
        :params="{
            media: 'la-chouette-agence/image/seo',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'SEO'"
        :text="`Ensuite, pour améliorer le SEO du site web, j'ai ajouté la balise de description, j'ai préservé le jus de lien et j'ai effacé les pratiques de black-hat du site web.`"
        />

        <MediaComment
        :params="{
            media: 'la-chouette-agence/image/access',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Accessibilité'"
        :text="`Enfin, pour améliorer l'accessibilité du site web, j'ai modifié le contraste des différents éléments, j'ai complété la sémantique HTML et j'ai ajouté tous les attributs HTML qui puissent aider les outils de navigation.`"
        />

        <Results 
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

        //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })
            .then(() => {
                this.$nextTick(() => {

                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){
            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref
        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>