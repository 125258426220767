<template>
    <section id="resizewindow" class="resize" :class="{resize_active: resizing == true, resize_inactive: resizing == false}">

        <!-- Phrase -->
        <p class="resize__phrase">La page attend la fin du redimensionnement ...</p>

    </section>
</template>

<script>
export default {
    name: "resize", 
    data(){
        return{
            resizing: false,
            date: undefined,
            delay: 1000,
            timeoutOut: undefined,
            width: undefined,
            height: undefined
        }
    },
    methods: {
        
        //! Fonction qui cache le responsive design
        resizeWindow(){

            //* Si la page est redimmensionné sur la largeur ...
            if(this.width !== window.innerWidth || this.height !== window.innerHeight){

                //* On redéfinit la largeur et la hauteur enrengistée
                this.width = window.innerWidth
                this.height = window.innerHeight

                //* On cache la scrollbar
                if(document.body.dataset?.overflow !== "hidden"){
                    document.body.dataset.overflow = "hidden"
                    document.body.style.overflow = "hidden"
                }

                //* On passe en mode resize
                this.resizing = true
                
                //* On annule l'appel précédent
                clearTimeout(this.timeoutOut)

                //* On lance un nouvelle appel
                this.timeoutOut = setTimeout(this.reload, this.delay)

            }
        },

        //! Rafraîchir la page
        reload(){

            //* Si on resize alors que la page est chargée ...
            if(this.$store.state.load !== false){

                //* On demande au parent de recalculer les fonctions de ses enfants
                this.$emit("reload", new Date())
                
                //* On désactive le mode resize
                this.resizing = false

                //* On rend visible la scrollbar
                document.body.dataset.overflow = "visible"
                document.body.style.overflow = "visible"

                this.$store.dispatch("sizeOfWindow")

                this.$router.push({name: this.$route.name})


            //* Sinon ...
            } else {
                location.reload()
            } 
            
        }

    },
    mounted(){
        //* On enrengistre la largeur de la page
        this.width = window.innerWidth

        //! Désactive sur mobile
        if(this.$store.state.isMobileNavigator !== true){
            window.addEventListener("resize", this.resizeWindow)
        }
    },
    beforeUnmount(){
        if(this.$store.state.isMobileNavigator !== true){
            window.removeEventListener("resize", this.resizeWindow)
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';

    .resize_active{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        position: fixed;
        z-index: 9999;
        background-color: rgb(255, 255, 255);
        inset: 0 0 0 0;
    }

    .resize_inactive{
        display: none;
    }

    .resize{

        &__phrase{
            font-family: $secondary-font;
            font-size: rem(20px);
            text-align: center;
            padding: 0 50px;
            line-height: 1.3;
        }
    }

</style>