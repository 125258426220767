<template>
    <section :class="{comment_left: direction == 'left', comment_right: direction == 'right'}" id="comment" class="comment">

        <!-- Media -->
        <div class="comment__media spook">

            <!-- Control (filtre) -->
            <div @click="playVideo($event)"  class="comment__media__control" name="cursorHoverVideo" :class="{comment_play: state == true, comment_pause: state == false}" :style="`background-color: ${color};`" >
                <span>Voir la démonstration</span>
            </div>

            <!-- Video -->
            <video playsinline loop class="comment__media__item" name="media_video" :height="params.height"  :width="params.width" preload="metadata" :src="require(`@/assets/project/${params.media}.mp4`)" ></video>

        </div>

        <!-- Titre et texte -->
        <div class="comment__explain">
            <h2 class="comment__explain__title spook">{{title}}/</h2>
            <p class="comment__explain__text spook">{{text}}</p>
        </div>

    </section>
</template>

<script>
export default {
    name: "MediaComment",
    data(){
        return{
            state: false
        }
    },
    props: {
        params: {type: Object, required: true},
        title: {type: String, required: true},
        text: {type: String, required: true},
        direction: {type: String, required: true},
        color: {type: String, required: true},
    },
    methods: {

        //! Permet de jouer la vidéo
        playVideo(event){
            
            const video = event.target.parentElement.children[1]

            //* Si la vidéo n'est pas jouer ...
            if(this.state == false){

                this.state = true

                // clearTimeout(timeoutPause)
            
                // var timeoutPlay = setTimeout(() => {
                    video.play()
                // }, 800)

            //* Sinon, si elle est est jouer ...
            } else {
                this.state = false

                // clearTimeout(timeoutPlay)

                // var timeoutPause = setTimeout(() => {
                    video.currentTime = 0
                    video.pause()
                // }, 800)

            }
            
        }

    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .comment_play{
        opacity: 0;   
    }

    .comment_pause{
        opacity: 1;   
    }

    .comment__left{
        flex-direction: row;
    }

    .comment_right{
        flex-direction: row-reverse;
    }

    .comment{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0px 5vw 200px 5vw;

        &__media{
            width: 63%;
            height: auto;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            overflow: hidden;
            position: relative;
            border: 1px solid var(--secondary-color);

            &__control{
                position: absolute;
                inset: 0 0 0 0;
                height: 100%;
                width: 100%;
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: opacity 0.8s ease-in-out;

                span{
                    margin-top: 25px;
                    font-size: mediaClamp(14, 20, 1px, 760);
                    text-transform: uppercase;
                    color: var(--primary-color);
                }
            }

            &__item{
                clip-path: inset(0px 1px 0px 0);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: cover;
                object-position: center;
            }
        }

        &__explain{
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            &__title{
                @include title-project-section;
            }

            &__text{
                @include text-project-section;
                margin-top: 50px;
            }
        }
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: 1000px) {

        .comment{
            padding: 0px 5vw 0px 5vw;
        }

        .comment, .comment__left, .comment_right{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .comment, .comment__explain, .comment__media{
            width: 100%;
        }

        .comment__media{
            margin-bottom: 0px;
        }

        .comment__explain__text{
            margin-top: 30px;
        }

        .comment__explain{
            margin: 80px 0 80px 0;
        }
    }


</style>