<template>
    <section id="info" class="info">

            <!-- Mission -->
            <div class="info__mission">
                <span v-for="item in mission" :key="item">{{item}}</span>
            </div>

            <div class="info__credits">

                <!-- Techno -->
                <div class="info__credits__techno">
                    <span v-for="item in techno" :key="item">{{item}}</span>
                </div>

                <!-- Auteur -->
                <div class="info__credits__author">
                    <span>{{author}}</span>
                    <div class="info__credits__author__date">
                        <span>© {{date}}</span>
                    </div>
                </div>
                
            </div>
            
    </section>
</template>

<script>
export default {
    name: "InfoProject",
    props: ['mission', 'techno', 'author', 'date']
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .info{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include padding-project();

        span{
            @include credit-project-section;
        }

        &__credits__techno, &__credits__author, &__mission{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__mission{
            flex: 5;
        }

        &__credits{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex: 2;

            &__author, &__techno{
                flex: 1;
            }

        }


        
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: $mobile-big-change) {

        .info{
            flex-direction: column;
        }

        .info__mission, .info__credits{
            width: 100%;
        }

        .info__mission{
            margin-bottom: 50px;
        }

        .info__credits__techno, .info__credits__author{
            flex: 1;
        }

    }

</style>