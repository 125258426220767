<template>
    <section id="sopekocko" class="sopekocko">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'so-pekocko/paralax/1',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 1.5,
                alt: 'Exemple de sauce chez So Pekocko'
            },
            {
                image: 'so-pekocko/paralax/2',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'top',
                vitesse: 1.5,
                alt: 'Exemple de sauce chez So Pekocko'
            },
            {
                image: 'so-pekocko/paralax/3',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 1.5,
                alt: 'Exemple de sauce chez So Pekocko'
            },
        ]"
        :rotate="-25"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="'Son activité principale était la création de sauces piquantes dont la composition est tenue secrète. Forte de son succès, l’entreprise souhaitait se développer et créer une application web, dans laquelle les utilisateurs devaient pouvoir ajouter leurs sauces préférées et liker ou disliker les sauces proposées par les autres ...'"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'so-pekocko/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à créer une API qui permettait à un utilisateur de créer un compte et de s'y connecter, de partager des sauces et de liker ou disliker celles des autres. 
        De plus, l'API devait respecter le RGPD et les standards OWASP.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/create_account',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Création d\'un compte'"
        :text="`Quand un utilisateur tente de créer un compte, un middleware se charge de normaliser et de vérifier si l'adresse mail est déjà présente dans la base de données.
        Si celle-ci ne l'est pas, elle est cryptée, son mot de passe est haché avec et un identifiant généré lui est associé.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/connect_account',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Connexion au compte'"
        :text="`Lorsqu'un utilisateur tente de se connecter, le mot de passe de la requête est comparé au mot de passe haché qui est associé à l'adresse mail de la requête. 
        Ainsi, s'il est valide, un jeton d'authentification contenant son identifiant est alors généré et attaché à toutes ces prochaines requêtes.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/create_sauce',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Création d\'une sauce'"
        :text="
        `À la soumission du formulaire de création, le jeton d'authentification est vérifié. 
        De même pour les champs grâce à des regs particuliers afin d'empêcher les erreurs de syntaxe, l'absence de valeur et l'injection de code.
        Ensuite si tout est bon, la sauce est enregistrée et associée à l'identifiant de l'utilisateur.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/update_sauce',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Modification d\'une sauce'"
        :text="`Lorsqu'un utilisateur tente de modifier une sauce, le jeton et les champs sont vérifiés. 
        Si l'identifiant de la requête correspond à l'identifiant associé à la sauce, alors la sauce est mise à jour.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/delete_sauce',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Suppression d\'une sauce'"
        :text="`Lorsqu'un utilisateur tente de supprimer une sauce, le jeton et les champs sont vérifiés. 
        Si l'identifiant de la requête correspond à l'identifiant associé à la sauce, alors la sauce est supprimée.`"
        />

        <MediaComment
        :params="{
            media: 'so-pekocko/image/like',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Like & Dislike'"
        :text="`Quand un utilisateur tente de liker/disliker une sauce, le jeton est vérifié et un middleware recherche l'état de son opinion sur celle-ci.
        Pour cela, le middleware récupère l'identifiant de la requête et effectué une recherche parmi la liste des personnes qui ont liker ou disliker cette sauce.
        Ensuite, grâce au résultat, son opinion et les compteurs de like/dislike sont mis à jour en conséquence.`"
        />

        <Results
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

        //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })
            .then(() => {
                this.$nextTick(() => {

                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){
            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref
        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>