<template>
    <section id="ohmyfood" class="ohmyfood">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'ohmyfood/paralax/menu',
                dimension: 45,
                height: 5434,
                width: 1000,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple de menu chez OhMyFood'
            },
            {
                image: 'ohmyfood/paralax/details',
                dimension: 45,
                height: 5434,
                width: 1000,
                defilement: 'top',
                vitesse: 2,
                alt: 'Exemple de plat chez OhMyFood'
            },
         
        ]"
        :rotate="15"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="'Pour OhMyFood, l\'objectif était de développer un site 100% mobile qui répertorie les menus de restaurants gastronomiques. En plus des systèmes classiques de réservation, les clients devaient pouvoir composer le menu de leur repas pour que les plats soient prêts à leur arrivée.'"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'ohmyfood/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à intégrer les maquettes mobiles fournit en adaptant le site aux résolutions mobiles, tablettes et bureau.`"
        />

        <MediaComment
        :params="{
            media: 'ohmyfood/image/interacte',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Effets d\'intéraction'"
        :text="`Il m'a été demandé d'implémenter des effets d'interaction sur les liens, les boutons et les menus selon leurs directives.`"
        />

        <MediaComment
        :params="{
            media: 'ohmyfood/image/apparition',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Effets d\'apparition'"
        :text="`De plus, je devais aussi intégrer un effet lors de l'apparition des différents menus.`"
        />

        <MediaComment
        :params="{
            media: 'ohmyfood/image/load',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Loading spinner'"
        :text="`D'une autre part, OhMyFood voulait que je crée un loading spinner selon ma créativité tout en préservant l'identité graphique de leur entreprise.`"
        />

        <Results 
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

        //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })
            .then(() => {
                this.$nextTick(() => {

                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){
            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref
        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>