import { createStore } from 'vuex'

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export default createStore({
    state: {
        load: false,
        isMobileNavigator: undefined,
        firstName: "Rida",
        lastName: "Benkou",
        mail: "contact@ridabenkou.com",
        github: "https://github.com/RedaPuenta",
        linkedin: "https://fr.linkedin.com/in/ridabenkou",
        navbartop: {
            text: {
                about: "A propos",
                work: "Atelier",
                home: "Accueil"
            }
        },
        transition: {
            page: {
                duration: {
                    apparition: 0.6,
                    focusDesktop: 1,
                    focusMobile: 0.4
                },
                ease: "power1.inOut",
            },
            project: {
                duration: {
                    video: 1.3,
                    other: 0.5
                },
                up: 70,
            }
        },
        listOfProject: [
            {
                name: "Reservia",
                ref: "reservia",
                media: {
                    logo: "reservia/logo/reservia.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "reservia/video/reservia_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "reservia/video/reservia_1600", media: 1600, width: 1600, height: 900},
                        {source: "reservia/video/reservia_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "reservia/video/reservia_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "reservia/video/reservia_800", media: 760, width: 800, height: 1422},
                        {source: "reservia/video/reservia_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "reservia/paralax1/reservia_1920", media: Infinity, width: 1920, height: 1296},
                        {source: "reservia/paralax1/reservia_1200", media: 1200, width: 1200, height: 694},
                        {source: "reservia/paralax1/reservia_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "Reservia est une petite entreprise proposant un outil de planification de vacances.",
                mission: [
                    "Intégration de maquette web"
                ],
                techno: ["HTML", "CSS"],
                author: 'Rida Benkou',
                date: 2021,
                color: "#0065FC",
                typo: ["Raleway"],
                results: [
                    {
                        text: "Site web",
                        link: "https://redapuenta.github.io/Reservia/"
                    },
                    {
                        text: "GitHub",
                        link: "https://github.com/RedaPuenta/Reservia"
                    }
                ]
            },
            {
                name: "OhMyFood",
                ref: "ohmyfood",
                media: {
                    logo: "ohmyfood/logo/ohmyfood.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "ohmyfood/video/ohmyfood_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "ohmyfood/video/ohmyfood_1600", media: 1600, width: 1600, height: 900},
                        {source: "ohmyfood/video/ohmyfood_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "ohmyfood/video/ohmyfood_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "ohmyfood/video/ohmyfood_800", media: 760, width: 800, height: 1422},
                        {source: "ohmyfood/video/ohmyfood_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "ohmyfood/paralax1/ohmyfood_1920", media: Infinity, width: 1920, height: 1296},
                        {source: "ohmyfood/paralax1/ohmyfood_1200", media: 1200, width: 1200, height: 694},
                        {source: "ohmyfood/paralax1/ohmyfood_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "Ohmyfood est une jeune startup qui voudrait s'imposer sur le marché de la restauration.",
                mission: [
                    "Intégration de maquette web",
                    "Dynamiser une page en web"
                ],
                techno: [
                    "HTML","CSS", "SASS"
                ],
                author: 'Rida Benkou',
                date: 2021,
                color: "#8c42e6",
                typo: ["Shrikhand", "Roboto"],
                results: [
                    {
                        text: "Site web",
                        link: "https://redapuenta.github.io/OhMyFood/"
                    },
                    {
                        text: "GitHub",
                        link: "https://github.com/RedaPuenta/OhMyFood"
                    }
                ]
            },
            {
                name: "La Chouette Agence",
                ref: "lachouetteagence",
                media: {
                    logo: "la-chouette-agence/logo/la-chouette-agence.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "la-chouette-agence/video/la-chouette-agence_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "la-chouette-agence/video/la-chouette-agence_1600", media: 1600, width: 1600, height: 900},
                        {source: "la-chouette-agence/video/la-chouette-agence_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "la-chouette-agence/video/la-chouette-agence_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "la-chouette-agence/video/la-chouette-agence_800", media: 760, width: 800, height: 1422},
                        {source: "la-chouette-agence/video/la-chouette-agence_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "la-chouette-agence/paralax1/la-chouette-agence_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "la-chouette-agence/paralax1/la-chouette-agence_1200", media: 1200, width: 1200, height: 694},
                        {source: "la-chouette-agence/paralax1/la-chouette-agence_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "La Chouette Agence est une grande agence de web design basée à Lyon.",
                mission: [
                    "Améliorer le SEO d'un site web.",
                    "Améliorer l'accessibilité d'un site web."
                ],
                author: 'Rida Benkou',
                date: 2021,
                color: "#e87743",
                techno: [
                    "HTML", "CSS"
                ],
                results: [
                    {
                        text: "Rapport d'analyse",
                        link: "https://docs.google.com/spreadsheets/d/1xDKfrIz2WRtxizSRoG3fgX0CXtFu0ea0xA7aTx5NAlM/edit#gid=1135277239"
                    },
                    {
                        text: "Rapport d'optimisation",
                        link: "https://redapuenta.github.io/LaChouetteAgence_RA/"
                    },
                    {
                        text: "GitHub (avant optimisation)",
                        link: "https://github.com/RedaPuenta/LaChouetteAgence_Before"
                    },
                    {
                        text: "Site web (avant optimisation)",
                        link: "https://redapuenta.github.io/LaChouetteAgence_Before/"
                    },
                    {
                        text: "GitHub (après optimisation)",
                        link: "https://github.com/RedaPuenta/LaChouetteAgence_After"
                    },
                    {
                        text: "Site web (après optimisation)",
                        link: "https://redapuenta.github.io/LaChouetteAgence_After/"
                    }
                ]
            },
            {
                name: "Orinoco",
                ref: "orinoco",
                media: {
                    logo: "orinoco/logo/orinoco.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "orinoco/video/orinoco_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "orinoco/video/orinoco_1600", media: 1600, width: 1600, height: 900},
                        {source: "orinoco/video/orinoco_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "orinoco/video/orinoco_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "orinoco/video/orinoco_800", media: 760, width: 800, height: 1422},
                        {source: "orinoco/video/orinoco_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "orinoco/paralax1/orinoco_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "orinoco/paralax1/orinoco_1200", media: 1200, width: 1200, height: 694},
                        {source: "orinoco/paralax1/orinoco_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "Orinoco est une entreprise de commerce en ligne.",
                mission: [
                    "Créer le MVP d'un site e-commerce"
                ],
                author: 'Rida Benkou',
                date: 2021,
                color: "#e38911",
                techno: [
                    "HTML", "CSS", "JavaScript"
                ],
                results: [
                    {
                        text: "Plan de test",
                        link: "https://drive.google.com/file/d/115UcnE1N5LYyxPMmWcY_TQDpTcRDHkMw/view"
                    },
                    {
                        text: "GitHub",
                        link: "https://github.com/RedaPuenta/Orinoco"
                    },
                    {
                        text: "Site web",
                        link: "https://redapuenta.github.io/Orinoco/"
                    }
                ]
            },
            {
                name: "So Pekocko",
                ref: "sopekocko",
                media: {
                    logo: "so-pekocko/logo/so-pekocko.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "so-pekocko/video/so-pekocko_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "so-pekocko/video/so-pekocko_1600", media: 1600, width: 1600, height: 900},
                        {source: "so-pekocko/video/so-pekocko_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "so-pekocko/video/so-pekocko_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "so-pekocko/video/so-pekocko_800", media: 760, width: 800, height: 1422},
                        {source: "so-pekocko/video/so-pekocko_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "so-pekocko/paralax1/so-pekocko_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "so-pekocko/paralax1/so-pekocko_1200", media: 1200, width: 1200, height: 694},
                        {source: "so-pekocko/paralax1/so-pekocko_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "So Pekocko est une entreprise familiale de 10 salariés qui créer des sauces piquantes",
                author: 'Rida Benkou',
                date: 2021,
                mission: [
                    "Créer une base de donnée",
                    "Construire une API sécurisée"
                ],
                color: "#F0774B",
                techno: [
                    "NodeJS", "ExpressJS",  "MongoDB"
                ],
                results: [
                    {
                        text: "GitHub",
                        link: "https://github.com/RedaPuenta/SoPekocko"
                    },
                    {
                        text: "Site web",
                        link: "https://reda-serveur.com/warn/sopekocko/"
                    }
                ]
            },
            {
                name: "Groupomania",
                ref: "groupomania",
                media: {
                    logo: "groupomania/logo/groupomania.svg",
                    video: undefined,
                    listOfVideo: [
                        {source: "groupomania/video/groupomania_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "groupomania/video/groupomania_1600", media: 1600, width: 1600, height: 900},
                        {source: "groupomania/video/groupomania_1300", media: 1300, width: 1300, height: 731.25},
                        {source: "groupomania/video/groupomania_1000", media: 1000, width: 1000, height: 562.5},
                        {source: "groupomania/video/groupomania_800", media: 760, width: 800, height: 1422},
                        {source: "groupomania/video/groupomania_420", media: 450, width: 420, height: 747},
                    ],
                    paralax1: undefined, 
                    listOfParalax1: [
                        {source: "groupomania/paralax1/groupomania_1920", media: Infinity, width: 1920, height: 1080},
                        {source: "groupomania/paralax1/groupomania_1200", media: 1200, width: 1200, height: 694},
                        {source: "groupomania/paralax1/groupomania_600", media: 700, width: 600, height: 1067},
                    ],
                },
                presentation: "Groupomania est un groupe spécialisé dans la grande distribution.",
                author: 'Rida Benkou',
                date: 2021,
                mission: [
                    "Créer une base de donnée",
                    "Créer un réseau social d'entreprise"
                ],
                color: "#D1515A",
                techno: [
                    "VueJS", "NodeJS", "MySQL"
                ],
                results: [
                    {
                        text: "GitHub",
                        link: "https://github.com/RedaPuenta/Groupomania"
                    },
                    {
                        text: "Site web",
                        link: "https://reda-serveur.com/warn/groupomania/"
                    }
                ]
            },
        ],
        music: [
            {page: "home", list: [
                {trigger: "#landingpage", mgt: 0, src: "grounded.mp3"},
                // {trigger: "#projectshow", mgt: 0, src: "grounded.mp3"},
                {trigger: "#workflow", mgt: 0, src: "the-land-of-sun.mp3"},
            ]},
            {page: "atelier", list: [
                {trigger: "#titlesection", mgt: 0, src: "grounded.mp3"},
                // {trigger: "#projectshow", mgt: 0, src: "grounded.mp3"},
                {trigger: "#contactme", mgt: 0, src: "the-land-of-sun.mp3"},
            ]},
            {page: "about", list: [
                {trigger: "#titlesection", mgt: 0, src: "elysium.mp3"},
                // {trigger: "#specialityjob", mgt: 0, src: "reunion.mp3"},
                {trigger: "#keenwork", mgt: 0.5, src: "duduk.mp3"},
            ]},
            {page: "project", list: [
                {src: "meditation.mp3"},
            ]},
        ]

    },
    getters: {
    },
    mutations: {

        //! Changer la variable "load"
        SET_LOAD(state, payload){
            state.load = payload
        },  

        //! Changer la valeur du témoin qui indique si l'on se trouve sur un navigateur mobile ou desktop
        SET_MOBILE_NAVIGATOR(state, payload){
            state.isMobileNavigator = payload
        },

        //! Vidéo responsive
        SET_VIDEO_RESPONSIVE(state, payload){
            const project = state.listOfProject[payload.indexOfProject]
            const currentMedia = project.media.listOfVideo[payload.indexOfMedia]
            state.listOfProject[payload.indexOfProject].media.video = currentMedia
        },

        //! Paralax1 responsive
        SET_PARALAX1_RESPONSIVE(state, payload){
            const project = state.listOfProject[payload.indexOfProject]
            const currentMedia = project.media.listOfParalax1[payload.indexOfMedia]
            state.listOfProject[payload.indexOfProject].media.paralax1 = currentMedia
        }
    },
    actions: {

        //! Déblocage du scroll
        deblockScrollStyle(){
            const styleInject = document.getElementsByName("transition-scrollbar")
            styleInject.forEach((element) => {
                element.remove()
            })
        },

        //! Blocage du scroll
        blockScrollStyle(){
            const style = document.createElement("style")
            style.setAttribute("name", "transition-scrollbar")
            const body = document.body
            body.appendChild(style)
            const styleInject = document.getElementsByName("transition-scrollbar")[0]
            styleInject.innerHTML= `body{overflow: hidden!important}`
        },

        //! Anit bug overflow sur les appareils mobiles
        overflowHiddenMobile(context){
           
            if(context.state.isMobileNavigator == true){
                gsap.set(`
                    .special__contain,
                    .keen__contain__range
                `, 
                {overflow: "hidden"})
            }
            

        },

        //! Définir les valeurs de la hauteur et largeur de la fenêtre dans le CSS
        sizeOfWindow(){

            //* Récupérer la valeur 1/100 de la hauteur de la fenêtre
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            //* Récupérer la valeur 1/100 de la largeur de la fenêtre
            let vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vw', `${vw}`);

        },

        //! Effet d'apparition
        spookAnimation(){

            const spooks = document.getElementsByClassName("spook")
    
            for (let i = 0; i < spooks.length; i++) {

                gsap.from(spooks[i], {
                    scrollTrigger: {
                        trigger: spooks[i],
                        start: "top 95%"
                    },
                    opacity: 0,
                    y: "50px",
                    ease: "power1.inOut",
                    duration: 0.4
                })

            }

            gsap.from(".spook-stagger", {
                scrollTrigger: {
                    trigger: ".results__contain",
                    start: "top 85%"
                },
                stagger: 0.3,
                opacity: 0,
                y: "50px",
                ease: "power1.inOut",
                duration: 0.4
            })

        },

        //! Supprime toutes les méthodes GSAP des composants relatif au montage
        deleteAllGSAP(context, payload){

            //* On supprime tout les ScrollTrigger GSAP sauf ceux pour la music
            if(payload.clearScrollTrigger == true){

                const all = ScrollTrigger.getAll()

                for (let i = 0; i < all.length; i++) {

                    if(all[i].vars.id?.includes("scroll-music") !== true){
                        all[i].kill()
                    }
                    
                }

            }

            //* On supprime toutes les méthodes GSAP dans les "media queries GSAP"
            if(payload.clearMatchMedia == true){
                ScrollTrigger.clearMatchMedia()
            }
            
            //* On supprime les positions de défilement de la mémoire de ScrollTrigger
            if(payload.clearScrollMemory == true){
                ScrollTrigger.clearScrollMemory()
            }

            //* On supprime toutes les animations et chronologie GSAP
            if(payload.clearAnimation == true){
                gsap.globalTimeline.getChildren().forEach((element) => {
                    element.kill()
                })
            }

        }
    },
    modules: {
    }
})
