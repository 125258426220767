import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({ 
    autoRefreshEvents: "DOMContentLoaded,load",
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(gsap)

app.mount('#app')

app.config.unwrapInjectedRef = true
app.config.globalProperties.gsap = gsap
app.config.globalProperties.scrollTrigger = ScrollTrigger

app.directive('color', {

    created(el, binding){
        const transparent = 0.3

        if(binding.modifiers.red){
            el.style.backgroundColor = `rgba(255, 0, 0, ${transparent})`
        } else if(binding.modifiers.blue){
            el.style.backgroundColor = `rgba(0, 0, 255, ${transparent})`
        } else if(binding.modifiers.green){
            el.style.backgroundColor = `rgba(0, 128, 0, ${transparent})`
        } else if(binding.modifiers.yellow){
            el.style.backgroundColor = `rgba(255, 255, 0, ${transparent})`
        }
    }
})

app.directive('relative', {
    created(el){
        el.style.position = "relative"
    }
})

app.directive('self', {
    created(el){
        el.style.position = "absolute"
        el.style.inset = "0 0 0 0"
        el.style.width = "100%"
        el.style.height = "100%"
        el.style.zIndex = "10"
    }
})