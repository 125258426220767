<template>
    <section :class="{open: display.show == true, close: display.show == false}" id="music" class="music">

        <!-- Boîte de dialogue -->
        <div name="cursorHoverLink" class="music__contain">

            <!-- Bouton -->
            <div class="music__contain__action">

                <!-- Play -->
                <button aria-label="Jouer la musique" v-if="display.paused == true">
                    <div class="self" @click="action()"></div>
                    <svg class="music__contain__action__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 418.91 512">
                        <path d="M12,509.08a23.26,23.26,0,0,0,23.62-.62L408,275.74a23.27,23.27,0,0,0,0-39.47L35.61,3.54A23.27,23.27,0,0,0,0,23.27V488.73A23.26,23.26,0,0,0,12,509.08Z"/>
                    </svg>
                </button>

                <!-- Pause -->
                <button aria-label="Mettre en pause la musique" v-if="display.paused == false">
                    <div class="self" @click="action()"></div>
                    <svg class="music__contain__action__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.08 512">
                        <path d="M247.39,0H358.56a12.53,12.53,0,0,1,12.52,12.53V499.47A12.53,12.53,0,0,1,358.56,512H247.39a12.53,12.53,0,0,1-12.53-12.53V12.53A12.53,12.53,0,0,1,247.39,0Z"/><path class="cls-1" d="M12.53,0H123.69a12.53,12.53,0,0,1,12.53,12.53V499.47A12.53,12.53,0,0,1,123.69,512H12.53A12.53,12.53,0,0,1,0,499.47V12.53A12.53,12.53,0,0,1,12.53,0Z"/>
                    </svg>
                </button>

                <!-- Toggle -->
                <div class="music__contain__action__dots">
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                    <div class="music__contain__action__dots__item"></div>
                </div>

            </div>

            <!-- Texte -->
            <div v-if="display.text == true" class="music__contain__text">
                <span>Musique</span>
            </div>
            
             <!-- Extension du "cursorHover" et "clique event" -->
            <div @click="show()" class="music__contain__extend"></div>

        </div>

        <!-- Signal -->
        <div v-if="display.signal == true" @click="show()" class="music__signal">

            <!-- Music -->
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                    <path d="M5877.2,4397.1c-2487.2-486.7-3182.8-630.4-3272.9-670.7c-76.7-34.5-147.5-86.2-206.9-151.4c-168.6-183.9-157.1,32.6-157.1-2816.8v-2514.1l-99.6,17.2c-130.3,24.9-429.2,7.7-590.2-32.6c-525-134.1-965.8-576.8-1103.7-1111.4c-47.9-185.9-47.9-563.4,0-747.3c147.5-559.5,551.9-958.1,1132.5-1117.1c157.1-44.1,551.9-44.1,709,0c410.1,113,712.8,327.7,929.4,661.1c92,139.9,143.7,256.7,206.9,465.6c28.7,95.8,32.6,325.8,38.3,2420.2c1.9,1272.4,9.6,2312.9,13.4,2312.9c9.6,0,4687,891,4815.4,917.9l80.5,17.2V718.1V-611.8l-51.7,13.4c-30.7,9.6-153.3,13.4-274,13.4c-274-3.8-452.2-47.9-691.7-172.5c-523.1-270.2-854.6-854.6-816.3-1442.9c13.4-222.3,55.6-377.5,157.1-586.4c187.8-387.1,517.4-663,948.5-799.1c122.6-38.3,183.9-44.1,421.6-44.1c237.6,0,298.9,5.7,421.6,44.1c431.2,136.1,760.7,412,948.5,799.1c168.6,344.9,153.3-26.8,153.3,3918.6V4629l-46,99.6c-82.4,185.9-247.2,295.1-433.1,291.3C9067.7,5018,7613.3,4738.2,5877.2,4397.1z"/>
                </g>
            </svg>

        </div>

        <!-- Piste audio -->
        <div :id="`music-${item.page}`" v-for="item in $store.state.music" :key="item">
            <audio loop preload="auto" :data-track="index + 1" :class="`music-${index + 1}`" v-for="(music, index) in item.list" :key="music">
                <source :src="require(`@/assets/audio/compress/${music.src}`)" />
            </audio>
        </div>
        
    </section>
</template>

<script>
import {ScrollTrigger} from "gsap/ScrollTrigger"
import { v4 as uuidv4 } from 'uuid'

export default {
    name: "Music",
    props: ["mobileActived"],
    data(){
        return{
            debug: false,
            route: {
                current: undefined,
                back: undefined
            },
            load: {
                acceptMusic: false,
            },
            track: {
                index: undefined,
                await: undefined
            },
            display: {
                paused: true,
                show: false,
                signal: true,
                text: false,
            },
            transition: {
                duration: {
                    normal: 1,
                    transit: 3
                },
                step: 0.05
            },
            resize: {
                width: undefined,
                height: undefined,
                inResizing: false,
                delay: 1000,
            }
        }
    },
    watch:{

        "$route": function(to, from){

            this.route.current = to.meta.music
            this.route.back = from?.meta.music
        }

    },
    methods: {

        //! Apparition de la boîte de dialogue
        apparition(){
            
            //* La boîte de dialogue devient visible
            const visibility = () => {
                const music = document.getElementById("music")
                music.style.visibility = "visible"
            }
            
            //* On place la boîte de dialogue au bonne endroit
            const placement = () => {

                var navbartop
                if(this.mobileActived == false){
                    navbartop = document.getElementById("navbartop")
                } else {
                    navbartop = document.getElementById("navbartopmobile")
                }

                const gsap = this.gsap
                const heightOfNavBarTop = gsap.getProperty(navbartop, "height")

                gsap.set(music, {
                    top: heightOfNavBarTop + (0.1 * window.innerHeight)
                })

            }
            
            visibility()
            placement()
            
        },
        
        //! Active/Desactive la bonne icône d'action + Active/Desactive la musique
        action(){

            //* On active/desactive la bonne icône d'action
            const choiceIcone = () => {
                this.display.paused = !this.display.paused
            }
            
            //* On active/desactive la music
            const choiceStateMusic = () => {
                switch (this.display.paused) {
                    case false:
                        this.play("click")
                    break
                    case true:
                        this.pause("click")
                    break
                }
            }

            choiceIcone()
            choiceStateMusic()
        
        },

        //! Ouvrir/Fermer la boîte de dialogue + Desactiver le signal
        show(){

            const showDialog = () => {
                this.display.show = !this.display.show
            }

            const desactiveSignal = () => {
                if(this.display.signal == true){
                    this.display.signal = false
                }
            }
            
            showDialog()
            desactiveSignal()

        },

        //! Réinitialiser la progression des audios de la page à leur début
        resetCurrentTimeAudios(){

            const audio = document.querySelectorAll(`#music-${this.route.current} audio`)
        
            audio.forEach((element) => {
                element.currentTime = 0
            })

        },

        //! Réinitialiser le cursor
        resetCursor(){
            if(this.mobileActived == false){

                const cursor = document.getElementById("cursorcustom")
                cursor.classList.remove("cursor-link")
                cursor.classList.add("cursor-base")

            }
            
        },  

        //! Jouer une musique
        play(origin){
        
            //* Le client accepte la musique sur le site
            const clientAcceptMusic = () => {
                this.load.acceptMusic = true
            }
            
            //* On joue la musique courante
            const playCurrentMusique = () => {

                const currentAudio = document.querySelectorAll(`#music-${this.route.current} audio`)[this.track.index - 1]
                currentAudio.volume = 0

                let hash = uuidv4()
                this.track.await = hash
                this.transitTrack(currentAudio, "start", origin == undefined ? "play" : origin, hash)
            }

            clientAcceptMusic()
            playCurrentMusique()
            
            //* On ferme la boîte de dialogue
            if(origin == "click"){
                this.show()
            }

            this.resetCursor()
        },

        //! Pause
        pause(origin){

            const currentAudio = document.querySelectorAll(`#music-${this.route.current} audio`)[this.track.index - 1]

            let hash = uuidv4()
            this.track.await = hash
                
            //* On met la musique courante en pause
            this.transitTrack(currentAudio, "end", origin == undefined ? "end" : origin, hash)
            
            //* On ferme la boîte de dialogue
            if(origin == "click"){
                this.show()
            }

            this.resetCursor()

        },
        
        //! Tout en pause
        allPause(origin){

            const allCurrentAudio = document.querySelectorAll(`#music-${this.route.back} audio`)

            allCurrentAudio.forEach((element) => {
                
                //* On met la musique courante en pause
                this.transitTrack(element, "end", origin == undefined ? "end" : origin, false)
                
            })

        },

        //! Jouer avec une transition
        transitTrack(audio, action, origin, hash){

            const etape = 4

            //: DEBUG 1 --> Tout connaître de la transitTrack()
            const transitTrackInfo = () => {

                if(this.debug == true){
                    console.log(`%cTRANSITTRACK()`, 'color: #4cc9f0; font-size: 20px; font-weight: bold')
                    console.table({action: action, audio: audio.children[0].src, origin: origin, hash: hash})
                }

            }

            //: DEBUG 2 --> Checkpoint1 --> Règle pour jouer une musique
            const checkPoint = () => {

                const list = [
                    {
                        rules: this.load.acceptMusic !== false, 
                        bad: "Le client n'a pas accepté de jouer la musique",
                        good: "Le client a accepté de joué de la musique"
                    },
                    {
                        rules: this.resize.inResizing == false,
                        bad: "La page est en cours de redimensionnement",
                        good: "La page n'est pas en cours de redimensionnement"
                    },
                    {
                        rules: (this.display.paused == false || origin !== "scrollTrigger"),
                        bad: "Un scrollTrigger veut lancer une musique sur pause",
                        good: "La musique n'est pas sur pause"
                    }
                ]
                
                var point = 0
                for (let i = 0; i < list.length; i++) {
                    
                    if(list[i].rules == true){
                        point++
                        if(this.debug == true){
                            console.log(`%c${list[i].good} | ${i + 1}/${etape}`, "color: green;")
                        }
                    } else {
                        if(this.debug == true){
                            point = 0
                            console.log(`%c${list[i].bad} | ${i + 1}/${etape}`, "color: red;")
                            break
                        }
                    }
                    
                }

                if(point == list.length){
                    return true
                } else {
                    return false
                }

            }

            //: DEBUG 3 --> Hash courant
            const currentHash = () => {
                if(this.debug == true){
                    console.log(this.track.await)
                }
            }

            transitTrackInfo()

            // - METTRE A JOUR L'INDEX DE LA MUSIQUE EN COURS
            if(action == "start" && this.resize.inResizing == false){
                this.track.index = parseInt(audio.dataset.track)

                if(this.debug == true){
                    console.log(`%cL'index de la musique courante --> ${this.track.index}`, "color: yellow;")
                }
            }

            if(checkPoint() == true){

                currentHash()

                //* Si la musique est déjà en pause, on la joue ...
                if(audio.paused == true && action == "start"){
                    audio.play()
                }

                //* Les valeurs de temps de transition
                const transition = {
                    step : this.transition.step,
                    duration: origin == "app" ? this.transition.duration.transit : this.transition.duration.normal
                }

                //* Les paramètres de la transition
                const params = {}

                switch (action) {

                    //* Paramètre de transition pour une musique entrante
                    case "start":

                            params.volumeStart = 0,

                            params.volumeEnd = 1,

                            params.setVolume = () => {
                                audio.volume = Math.min(1, audio.volume + transition.step) 
                                if(audio.paused == true){
                                    audio.play()
                                }
                            },

                            params.setFinal = () => {
                                audio.play()
                            }
                    
                    break

                    //* Paramètre de transition pour une musique sortante
                    case "end":

                        params.volumeStart = origin !== "app" ? 1 : audio.volume,

                        params.volumeEnd = 0,

                        params.setVolume = () => {
                            audio.volume = Math.max(0, audio.volume - transition.step)
                        },

                        params.setFinal = () => {
                            audio.pause()
                        }

                    break

                }

                //* Intervale d'augmentation de volume
                const transitionAudio = setInterval(() => {
                    
                    //* Si les opérations sont les courantes
                    if(this.track.await == hash || origin == "app"){
            
                        //- On augmente le volume d'un pas
                        params.setVolume()
                        
                        //* Si le volume est au maximum, on détruit l'interval
                        if(audio.volume == params.volumeEnd) {

                            params.setFinal()
                        
                            clearInterval(transitionAudio)
                        }

                    } else {
                        clearInterval(transitionAudio)
                    }

                }, (transition.duration * 1000) / (1 / transition.step))
            
            }

        },

        //! Construire une timeline de musique au scroll
        buildScrollMusic(list){

            const debug = this.debug
            const route = this.route.current

            const transitTrack = (audio, action, origin, hash) => {
                this.transitTrack(audio, action, origin, hash)
            }

            for (let i = 0; i < list.length; i++) {

                const top = `top-=${list[i].mgt * window.innerHeight}px`

                if(i == 0){

                    ScrollTrigger.create({
                        id: `scroll-music-${this.route.current}-${i + 1}`,
                        trigger: list[i].trigger,
                        start: `${top} center`,
                        markers: debug,
                        onEnter: () => {
                            let hash = uuidv4()
                            this.track.await = hash
                            transitTrack(document.querySelector(`#music-${route} .music-${i + 1}`), "start", 'scrollTrigger', hash)
                        }
                    })

                } else {

                    ScrollTrigger.create({
                        id: `scroll-music-${this.route.current}-${i + 1}`,
                        trigger: list[i].trigger,
                        start: `${top} center`,
                        markers: debug,
                        onEnter: () => {
                            let hash = uuidv4()
                            this.track.await = hash
                            transitTrack(document.querySelector(`#music-${route} .music-${i + 1}`), "start", "scrollTrigger", hash)
                            transitTrack(document.querySelector(`#music-${route} .music-${i}`), "end", "scrollTrigger", hash)
                        }, 
                        onLeaveBack: () => {
                            let hash = uuidv4()
                            this.track.await = hash
                            transitTrack(document.querySelector(`#music-${route} .music-${i}`), "start", "scrollTrigger", hash)
                            transitTrack(document.querySelector(`#music-${route} .music-${i + 1}`), "end" , "scrollTrigger", hash)
                            if(document.querySelector(`#music-${route} .music-${i + 2}`) !== null){
                                transitTrack(document.querySelector(`#music-${route} .music-${i + 2}`), "end", "scrollTrigger", hash)
                            }
                        }
                    })

                }
                
            }

        },

        //! Contruire une timeline de musique
        buildMusic(){

            this.track.index = 1

            //* Si le client a accepté la musique ...
            if(this.display.paused == false){
                this.play(true)
            }
            
        },

        //! Détruire les ScrollAnimation
        destroyScroller(){

            const all = ScrollTrigger.getAll()

            for (let i = 0; i < all.length; i++) {

                if(all[i].vars.id?.includes("scroll-music") == true){
                    all[i].kill()
                }
                
            }
                
        },

        //! Installer le scrollerAnimation
        installScroller(){

            return new Promise((resolve) => {

                this.resetCurrentTimeAudios()

                const listOfMusic = this.$store.state.music
                const indexPage = listOfMusic.findIndex((value) => value.page == this.route.current)

                if(this.route.current !== "project"){
                    this.buildScrollMusic(this.$store.state.music[indexPage].list)
                } else {
                    this.buildMusic()
                }

                resolve()
            })

            
        },

        //! Relancer les musiques
        reload(){

            this.installScroller()
            .then(() => {
                this.resize.inResizing = false
            })
            .then(() => {
                if(this.display.paused !== true){
                    this.play("resize")
                }
            })
            
        },

        //! Préserver la musique au "resize"
        resizePreserve(){

            if(this.resize.width !== window.innerWidth || this.resize.height !== window.innerHeight){

                if(this.resize.inResizing == false){
                    
                    //* On détruit les ScrollTriggers
                    this.destroyScroller()

                    //* On indique de que l'index de la musique courante est 1
                    this.track.index = 1

                    //* On reintialisize tout les audios
                    const audio = document.querySelectorAll("audio")
                    audio.forEach((element) => {
                        element.currentTime = 0
                        element.pause()
                    })
                
                }

                //* On monte tout en haut de la page
                scrollTo(0, 0)

                //* On redéfinit la largeur enrengistée
                this.resize.width = window.innerWidth
                this.resize.height = window.innerHeight

                //* On passe en mode resize
                this.resize.inResizing = true
                
                //* On annule l'appel précédent
                clearTimeout(this.timeoutOut)

                //* On lance un nouvelle appel
                this.timeoutOut = setTimeout(this.reload, this.resize.delay)

            }

        },

    },
    mounted(){
        
        window.addEventListener("load", () => {

            this.$nextTick(() => {

                this.apparition()
                this.installScroller()

                if(this.$store.state.isMobileNavigator !== true){
                    window.addEventListener("resize", () => {
                        this.resizePreserve()
                    })
                }

            })

        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .self{
        position: absolute;
        inset: 0 0 0 0;
        width: 100%;
        height: 100%;
    }

    .open{
        transform: translate(0%, -50%);
    }

    .close{
        transform: translate(-60px, -50%);
    }

    .music{
        visibility: hidden;
        position: fixed;
        top: 15vh;
        transition: transform 0.7s ease-in-out;
        left: 0px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        mix-blend-mode: exclusion;
        height: 45px;

        &__contain{
            height: 100%;
            width: 70px;
            background-color: var(--primary-color);
            // box-shadow: 1px 1px 5px rgba($color1, 0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;

            &__action{
                display: flex;
                justify-content: center;
                align-items: center;

                button{
                    position: relative;
                }

                &__icon{
                    height: 24px;
                    width: auto;
                    padding: 0px 5px;
                    fill: var(--secondary-color);
                }

                &__dots{
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    align-items: center;
                    padding-right: 4px;

                    &__item{
                        height: 2px;
                        width: 2px;
                        background-color: var(--secondary-color);
                    }
                }
                
            }

            &__text{
                position: absolute;
                transform: translateY(calc(100% + 1px));
                bottom: 0px;
                overflow: hidden;
                
                span{
                    letter-spacing: 1px;
                    white-space: nowrap;
                    font-size: rem(9px);
                    text-transform: uppercase;
                    font-size: 900;
                    color: var(--primary-color);
                }
            }

            &__extend{
                position: absolute;
                right: 0px;
                transform: translateX(50%);
                height: 100%;
                width: 40px;
            }

        }

        &__signal{
            height: 100%;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                mix-blend-mode: exclusion;
                width: auto;
                fill: rgba($color1, 0.1);
                height: 20px;
                animation: signal 1.5s ease-in-out infinite;

                @keyframes signal {
                    0%{
                        transform: scale(0.75);
                    }

                    50%{
                        transform: scale(1);
                    }

                    100%{
                        transform: scale(0.75);
                    }
                }

            }

        }


        audio{
            position: absolute;
            transform: translateX(-100vw);
            visibility: hidden;
            pointer-events: none
        }

    }

</style>