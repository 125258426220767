<template>
    <section class="details spook" id="details">

        <!-- Titre -->
        <h2 class="details__title">{{title}}/</h2>

        <!-- Texte -->
        <p class="details__text">{{text}}</p>

    </section>
</template>

<script>
export default {
    name: "Details",
    props: {
        title: {type: String, required: true},
        text: {type: String, required: true}
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .details{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: mediaClamp(80, 150, 1px, 500) mediaClamp(8, 15, 1vw, 500);
        width: 100%;

        &__title{
            @include title-project-section;
            margin-right: 7vw;
        }

        &__text{
            @include text-project-section;
            width: mediaClamp(60, 40, 1%, 1200);
        }
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: $mobile-big-change) {

        .details{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .details__title, .details__text{
            width: 100%;
        }

        .details__title{
            margin-right: 0;
            margin-bottom: 30px;
        }
        
    }

</style>