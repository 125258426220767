<template>
    <section :style="`background-image: url(${require(`@/assets/project/${backImage}`)}); background-color: ${color};`" id="paralaxone" class="paralaxone">

        <div class="paralaxone__filter"></div>
        <picture class="paralaxone__picture">
            <source :src="require(`@/assets/project/${$store.state.listOfProject[index - 1].media.paralax1.source}.png`)" type="image/png">
            <img class="paralaxone__picture__image" :height="$store.state.listOfProject[index - 1].media.video.height" :width="$store.state.listOfProject[index - 1].media.video.width" :src="require(`@/assets/project/${$store.state.listOfProject[index - 1].media.paralax1.source}.webp`)">
        </picture>
        

    </section>
</template>

<script>
export default {
    name: "paralaxOne",
    props: {
        index: {type: Number, required: true},
        backImage: {type: String, required: true},
        color: {type: String, required: true}
    },
    methods: {

        //! Rétablit le CSS de base (anti-bug --> resize + GSAP)
        saveCSS(){
            return new Promise((resolve) => {
                const gsap = this.gsap
                gsap.set(".paralaxone__picture__image", {clearProps: "all"})
                resolve()
            })
        },

        //! Toute la logique de l'effet au scroll
        scrollEffect(){

            const gsap = this.gsap
            const img = document.querySelector(".paralaxone__picture__image")

            //- Transition sur l'axe des Y
            gsap.fromTo(img, 
                {
                    y: "-20%"
                },
                {
                    scrollTrigger: {
                        trigger: ".paralaxone",
                        start: "-90% center",
                        end: "bottom center",
                        scrub: true
                    },
                    ease: "linear",
                    y: "10%"
                }
            )

        },

        //! Toutes les méthodes à utiliser lors du montage
        allMethodsLoad(){
            this.saveCSS()
            .then(() => {
                this.scrollEffect()
            })
        }

    },
    mounted(){
        this.$nextTick(() => {
            this.allMethodsLoad()
        })
    }
}
</script>

<style lang="scss">

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .paralaxone{
        @include media-project;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        overflow: hidden;
        height: 100vh;
        position: relative;
        background-position: 50% 10%;
        background-repeat: no-repeat;
        background-size: 60vw;

        &__filter{
            height: 120vh;
            width: 100%;
            inset: 0 0 0 0;
            z-index: 1;
            backdrop-filter: blur(7px);
            position: absolute;
        }

        &__picture{
            z-index: 2;

            &__image{
                height: auto;
                max-height: 100vh;
                width: auto;
                max-width: 90vw;
            }
        }
        
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: 1200px) {
        .paralaxone__picture__image{
            max-height: 90vh;
            max-width: 95vw;
        }
    }

   
    @media only screen and (max-width: $mobile-big-change) {
        .paralaxone{
            background-size: 90%;
        }

        .paralaxone__picture__image{
            max-height: 100vh;
            max-width: 95vw;
        }
    }


</style>