<template>
    <section id="groupomania" class="groupomania">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'groupomania/paralax/media',
                dimension: 45,
                height: 5434,
                width: 1000,
                defilement: 'top',
                vitesse: 2,
                alt: 'Exemple de publication de type multimédia chez Groupomania'
            },
            {
                image: 'groupomania/paralax/forum',
                dimension: 45,
                height: 5434,
                width: 1000,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple de publication de type forum chez Groupomania'
            },
        ]"
        :rotate="-15"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="`Cette entreprise souhaitait construire un réseau social interne pour ses employés. Le but de cet outil était de faciliter les interactions entre collègues. 
        Le département RH de Groupomania avait laissé libre cours à son imagination pour les fonctionnalités du réseau et avait imaginé plusieurs briques pour favoriser les échanges entre collègues.`"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'groupomania/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à créer de toutes pièces un réseau social adaptées à toutes les résolutions. 
        Les utilisateurs devaient pouvoir créer un compte, s'y connecter et le supprimer, personnaliser leur profil, accéder à un forum texte et multimédia, repérer facilement les dernières participations ... 
        De plus, un accès administrateur devait être établi afin de modérer les interactions.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/create_account',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Création d\'un compte'"
        :text="`Quand un utilisateur tente de créer un compte, un middleware se charge de normaliser et de vérifier si l'adresse mail existe est déjà dans la base de données. 
        Si celle-ci n'existe pas, elle est cryptée, son mot de passe est haché et un identifiant généré lui est associé. 
        Sinon, si celle-ci existe déjà, l'utilisateur en est informé.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/connect_account',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Connexion au compte'"
        :text="`Lorsqu'un utilisateur tente de se connecter, le mot de passe de la requête est comparé au mot de passe haché associé à l'adresse mail de la requête. 
        Ainsi, s'il est valide, un jeton d'authentification contenant son identifiant est alors généré et attaché à toutes ces prochaines requêtes.
        Sinon, s'il n'est pas valide, l'utilisateur en est informé.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/main_connect',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Maintien de la connexion'"
        :text="`Pendant le processus de connexion, l'utilisateur peut décider de maintenir sa connexion ou non.
        Ainsi s'il décide de la maintenir, les informations relatives à l'accession des pages seront enregistrées dans le stockage local du navigateur.
        Sinon, s'il décide de ne pas la maintenir, ces informations seront enregistrées dans le stockage de session du navigateur.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/delete_account',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Suppression d\'un compte'"
        :text="`Lorsqu'un utilisateur tente de supprimer son compte, son jeton est vérifié. 
        Ensuite, son compte, ses contenus, ses commentaires et ses opinions sont supprimés de la base de données grâce à des clés étrangères et des déclencheurs de suppression.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/create_contain',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Création d\'un contenu'"
        :text="`À la soumission du formulaire de création, le jeton d'authentification est vérifié. 
        De même pour les champs et le média grâce à des regex particuliers afin d'empêcher les erreurs de syntaxe, l'absence de valeur et l'injection de code.
        Ensuite, si tout est bon, le contenu est enregistré et associé à l'identifiant de l'utilisateur.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/filter',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Filtre de contenu'"
        :text="`Les contenus affichés peuvent êtres filtrés par date (Le fil d'actualité), par possession (Mes publications) et par préférence (Mes préférés).
        Ces différents types d'affichages sont rendus grâce à des jointures SQL.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/delete_contain',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Suppression d\'un contenu'"
        :text="`Lorsqu'un utilisateur tente de supprimer un contenu, le jeton vérifié. 
        Si l'identifiant de la requête correspond à l'identifiant associé au contenu, alors celui-ci ainsi que les commentaires et les opinions qui lui sont associé sont supprimés de la base de données grâce à des clés étrangères de suppression.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/create_comment',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Création d\'un commentaire'"
        :text="
        `Quand un utilisateur tente de commenter un contenu, le jeton et les champs sont vérifiés. 
        Puis, si tout est bon, son commentaire et son identifiant sont enregistrés.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/delete_comment',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Suppression d\'un commentaire'"
        :text="`Lorsqu'un utilisateur tente de supprimer un commentaire, le jeton vérifié. 
        Si l'identifiant de la requête correspond à l'identifiant associé au commentaire, alors celui-ci est supprimé.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/like',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Like & Dislike'"
        :text="`Quand un utilisateur tente de liker/disliker un contenu, le jeton est vérifié et un middleware recherche l'état de son opinion sur ce contenu.
        Pour cela, le middleware récupère l'identifiant de la requête et effectue une recherche parmi la liste des personnes qui ont liker ou disliker ce contenu.
        Ensuite, grâce au résultat, son opinion et les compteurs de like/dislike sont mis à jour en conséquence.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/list_profil',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Liste des profils'"
        :text="`Chaque profil contient un avatar, un nom, un prénom, le nombre de contenus édités par l'auteur ainsi qu'un score de participation et réaction.
        Ces scores sont calculés à partir d'un système de points inscrits dans l'API.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/custom_profil',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Personnalisation du profil'"
        :text="`Un utilisateur peut modifier ses informations personnelles et son icône de profil parmi une liste d'avatar. 
        Lorsqu'il tente de modifier son profil, son jeton et les champs sont vérifiés.
        Si l'identifiant de la requête correspond à l'identifiant associé au profil, alors celui-ci est modifier.`"
        />

        <MediaComment
        :params="{
            media: 'groupomania/image/admin',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Accès administrateur'"
        :text="`L'administrateur est déclaré comme un compte ayant un privilège supérieur à celui des utilisateurs. 
        Ainsi, pour toutes requêtes effectuées, un middleware avant-gardiste recherche le privilège de l'auteur de la requête.
        Si le privilège résultant est considéré comme un privilège d'administrateur, alors les pages donnent accès à tous les éléments d'actions possibles.
        Quant à ces requêtes, elles échappent au middleware du contrôle de propriété.`"
        />

        <Results
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

        //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })

            .then(() => {
                this.$nextTick(() => {
          
                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){
            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref
            
        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>