<template>
    <section id="results" class="results spook">

        <!-- Titre -->
        <h2 class="results__title">Liens</h2>

        <!-- Liste des lients -->
        <div class="results__contain">
            <a class="spook-stagger" name="cursorHoverLink" v-for="item in results" target="__blank" :key="item" :href="item.link" rel="nofollow">{{item.text}}</a>
        </div>
        
    </section>
</template>

<script>
export default {
    name: "result",
    props: {
        results: {type: Array, required: true}
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .results{
        padding: 0 5vw mediaClamp(80, 200, 1px, 1000) 5vw;

        &__title{
            @include title-project-section;
        }

        &__contain{
            margin-top: mediaClamp(30, 50, 1px, 1000);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

            a{
                @include credit-project-section;
                margin-right: 30px;
            }

        }
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: 1000px) {

        .results{
            margin-top: 20px;
        }

    }

</style>