<template>
    <div id="cursorcustom" class="cursor-base"></div>
</template>

<script>
export default {
    name: "CursorCustom",
    data(){
        return{ 
            position: {
                x: undefined,
                y: undefined
            },
            mouse: {
                x: undefined,
                y: undefined
            },
            speed: 0.35,
            xSet: undefined,
            ySet: undefined,
            cursor: {
                current: undefined,
                width: undefined,
                height: undefined
            }
        }
    },
    methods: {

        //! Rétablit le CSS de base (anti-bug --> resize + GSAP)
        saveCSS(){
            return new Promise((resolve) => {
                const gsap = this.gsap
                gsap.set("#cursorcustom, body", {clearProps: "all"})
                resolve()
            })
            
        },

        //! Curseur pour les liens
        hoverLink(){
            
            const cursor = document.getElementById("cursorcustom")

            const mouseEnterLink = () => {
                cursor.classList.remove("cursor-base")
                cursor.classList.add("cursor-link")
            }

            const mouseLeaveLink = () => {
                cursor.classList.remove("cursor-link")
                cursor.classList.add("cursor-base")
            }

            const links = document.getElementsByName("cursorHoverLink")

            links.forEach((element) => {
                element.addEventListener("mouseenter", mouseEnterLink)
                element.addEventListener("mouseleave", mouseLeaveLink)
            })

        },

        //! Curseur pour les vidéo de projet
        hoverVideo(){
            
            const cursor = document.getElementById("cursorcustom")

            const mouseEnterLink = (e) => {

                console.log("enter hover")

                console.log(e.target.dataset.click)

                cursor.classList.remove("cursor-base")

                if(e.target.dataset.click == undefined || e.target.dataset.click == "false" || e.target.dataset.click == false){
                    cursor.classList.remove("cursor-pause")
                    cursor.classList.add("cursor-play")
                } else {
                    cursor.classList.remove("cursor-play")
                    cursor.classList.add("cursor-pause")
                }
                
            }

            const mouseLeaveLink = () => {
                console.log("quit hover")
                cursor.classList.remove("cursor-play")
                cursor.classList.remove("cursor-pause")
                cursor.classList.add("cursor-base")
            }

            const click = (e) => {
                if(e.target.dataset?.click == undefined || e.target.dataset?.click == "false"){
                    e.target.dataset.click = "true"
                    cursor.classList.remove("cursor-play")
                    cursor.classList.add("cursor-pause")
                } else {
                    e.target.dataset.click = "false"
                    cursor.classList.remove("cursor-pause")
                    cursor.classList.add("cursor-play")
                }

            }

            const links = document.getElementsByName("cursorHoverVideo")

            links.forEach((element) => {
                element.addEventListener("mouseenter", mouseEnterLink)
                element.addEventListener("mouseleave", mouseLeaveLink)
                element.addEventListener("click", click)
            })

        },

        //! Curseur pour les projets
        hoverProject(){

            const cursor = document.getElementById("cursorcustom")

            const mouseEnterProject = () => {
                cursor.classList.remove("cursor-base")
                cursor.classList.add("cursor-project")
            }

            const mouseLeaveProject = () => {
                cursor.classList.remove("cursor-project")
                cursor.classList.add("cursor-base")
            }

            const projects = document.getElementsByName("cursorHoverProject")

            projects.forEach((element) => {
                element.addEventListener("mouseenter", mouseEnterProject)
                element.addEventListener("mouseleave", mouseLeaveProject)
            })

        },

        //! Curseur pour les cartes de langage
        hoverCard(){
            
            const cursor = document.getElementById("cursorcustom")

            const mouseEnterCard = () => {
                cursor.classList.remove("cursor-base")
                cursor.classList.add("cursor-card")
            }

            const mouseLeaveCard = () => {
                cursor.classList.remove("cursor-card")
                cursor.classList.add("cursor-base")
            }

            const cards = document.getElementsByName("cursorHoverCard")
   
            cards.forEach((element) => {
                element.addEventListener("mouseenter", mouseEnterCard)
                element.addEventListener("mouseleave", mouseLeaveCard)
            })

        },

        //! Ajout du curseur au document
        cursorEffect(){

            const gsap = this.gsap
            const cursor = document.getElementById("cursorcustom")
            
            this.position.x = window.innerWidth / 2
            this.position.y = window.innerHeight / 2 + 100 
            this.mouse.x = this.position.x
            this.mouse.y = this.position.y 
            this.xSet = gsap.quickSetter(cursor, "x", "px")
            this.ySet = gsap.quickSetter(cursor, "y", "px")

            window.removeEventListener("mousemove", this.mouseMove)
            window.addEventListener("mousemove", this.mouseMove)
           
            gsap.ticker.remove(this.forTicker)
            gsap.ticker.add(this.forTicker)
            
        },

        //! Suivi de la souris
        mouseMove(e){
            
            const currentCursor = document.getElementById("cursorcustom").classList[0]
            if(currentCursor !== this.cursor.current){
                
                const gsap = this.gsap
                this.cursor.current = currentCursor

                const heightOfCursor = gsap.getProperty(`.${currentCursor}`, "height")
                const widthOfCursor = gsap.getProperty(`.${currentCursor}`, "width")
                this.cursor.height = heightOfCursor
                this.cursor.width = widthOfCursor
            }
            
            this.mouse.x = e.x - (this.cursor.width / 2)
            this.mouse.y = e.y - (this.cursor.height / 2)
        },

        //! Fonction qui change les coordonnées du pointer (avec le moteur gsap)
        forTicker(){
            const gsap = this.gsap
            const dt = 1.0 - Math.pow(1.0 - this.speed, gsap.ticker.deltaRatio())
                
            this.position.x += (this.mouse.x - this.position.x) * dt
            this.position.y += (this.mouse.y - this.position.y) * dt
            this.xSet(this.position.x)
            this.ySet(this.position.y)
        },

        //! Toutes les méthodes à utiliser lors du montage
        allMethodsLoad(){
            this.saveCSS()
            .then(() => {
                this.cursorEffect() 
            })
           
        }
    },
    mounted(){
        
        this.$nextTick(() => {
            this.hoverLink()
            this.hoverProject()
            this.hoverCard()
            this.hoverVideo()
            this.allMethodsLoad()
        })
    },
    beforeUnmount(){
        window.removeEventListener("mousemove", this.mouseMove)
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';

    .cursor-base{
        width: 25px;
        height: 25px;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 50%;
        pointer-events: none;
    }

    .cursor-link{
        width: 40px;
        height: 40px;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        border: none;
        border-radius: 50%;
        pointer-events: none;
        background-color: var(--primary-color);
        border-radius: 50%;
        pointer-events: none;
        mix-blend-mode: difference;
    }

    .cursor-project{
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        border-radius: 50%;
        pointer-events: none;
        mix-blend-mode: exclusion;

        &::after{
            content: "Découvrir le projet";
            inset: 0 0 0 0;
            text-transform: uppercase;
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            font-family: $secondary-font;
        }
    }

    .cursor-play, .cursor-pause{
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        border-radius: 50%;
        pointer-events: none;
        transition: mix-blend-mode 0.8s ease-in-out;

        &::after{
            inset: 0 0 0 0;
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            font-family: $secondary-font;
        }
    }

    .cursor-play{
        mix-blend-mode: luminosity;
    
        &::after{
            content: "Lire"; 
        }
    }

    .cursor-pause{
        mix-blend-mode: exclusion;
        
        &::after{
            content: "Fermer"; 
        }
    }

    .cursor-card{
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        border-radius: 50%;
        pointer-events: none;
        border: none!important;
        mix-blend-mode: exclusion;
        
        &::after{
            content: "";
            inset: 0 0 0 0;
            height: 30px;
            width: 30px;
            background-image: url("../assets/icon/color.svg");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            mix-blend-mode: exclusion;
            text-transform: uppercase;
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            font-family: $secondary-font;
        }
    }


</style>