<template>
    <section id="orinoco" class="orinoco">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'orinoco/paralax/ours',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple de produit de type ours chez Orinoco'
            },
            {
                image: 'orinoco/paralax/camera',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'top',
                vitesse: 2,
                alt: 'Exemple de produit de type camera chez Orinoco'
            },
            {
                image: 'orinoco/paralax/meuble',
                dimension: 29,
                height: 3532,
                width: 650,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple de produit de type meuble chez Orinoco'
            },
        ]"
        :rotate="21"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="`Orinoco voulait créer un site e-commerce se démarquant des grands sites e-commerce comme Amazon en créant des applications thématiques ne vendant qu\'un seul groupe de produits. 
        Par exemple, Oribook pour les livres ou Oritextil pour les vêtements.`"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'orinoco/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à intégrer des appels API afin de récupérer les différents produits avec leurs informations, à construire un système de panier et à créer un formulaire de paiement.
        De plus, je devais réaliser à un plan de test.`"
        />

        <MediaComment
        :params="{
            media: 'orinoco/image/add_checkout',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Ajout au panier'"
        :text="`Chaque produit ajouté au panier créer automatiquement une clé de référence avec des valeurs tel que le nombre, le prix, la variante... Ces clés sont stockées temporairement dans la mémoire du navigateur.`"
        />

        <MediaComment
        :params="{
            media: 'orinoco/image/visu_checkout',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Visualisation du panier'"
        :text="`Les produits ayant la même référence et la même variante sont regroupés libérant ainsi un prix de groupe. De plus, le prix total du panier est généré avec l'addition de tous les prix.`"
        />

        <MediaComment
        :params="{
            media: 'orinoco/image/update_checkout',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Modification du panier'"
        :text="`Quand un visiteur modifie le nombre d'éléments dans son panier, le nombre de produits et le prix total du panier sont incrémentés en conséquence.`"
        />

        <MediaComment
        :params="{
            media: 'orinoco/image/form',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'right'"
        :title="'Formulaire de paiement'"
        :text="`Chaque champ contient un regex particulier afin de d'empêcher les erreurs de syntaxe, l'absence de valeur ou bien l'injection de code.`"
        />

        <MediaComment
        :params="{
            media: 'orinoco/image/confirm_checkout',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Confirmation de commande'"
        :text="`À la soumission du formulaire, les produits et les informations du client sont envoyés au serveur et un numéro de commande est remis au client.`"
        />

        <Results 
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

         //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })
            .then(() => {
                this.$nextTick(() => {

                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){
            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref
            
        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>

