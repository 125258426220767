import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const NotFound = () => import('@/views/NotFound.vue')
const HomeView = () => import('@/views/HomeView.vue')
const About = () => import('@/views/About.vue')
const Atelier = () => import ('@/views/Atelier.vue')
const Project = () => import('@/views/Project.vue')

import Reservia from '@/projects/reservia.vue'
import OhMyFood from '@/projects/ohmyfood.vue'
import LaChouetteAgence from '@/projects/lachouetteagence.vue'
import Orinoco from '@/projects/orinoco.vue'
import SoPekocko from '@/projects/sopekocko.vue'
import Groupomania from '@/projects/groupomania.vue'

const routes = [
    {
        path: '/:catchAll(.*)*',
        redirect: () => {
            return { name: 'home'}
        },
    },
    {
        path: '/', 
        name: 'home', 
        meta: {
            text: store.state.navbartop.text.home,
            title: "Accueil | Rida Benkou",
            music: "home"
        },
        component: HomeView
    },
    {
        path: '/atelier',
        name: 'atelier',
        meta: {
            text: store.state.navbartop.text.work,
            title: "Atelier | Rida Benkou",
            music: "atelier"
        },
        component: Atelier
    },

    {
        path: '/about',
        name: "about",
        meta: {
            text: store.state.navbartop.text.about,
            title: "A propos | Rida Benkou",
            music: "about"
        },
        component: About
    },

    {
        path: '/atelier', 
        name: 'project', 
        meta: {
            text: "Projet",
            music: "project"
        },
        component: Project,
        children: [

            {
                path: 'reservia',
                name: 'reservia',
                component: Reservia,
                meta: {
                    title: "Reservia | Rida Benkou",
                    music: "project"
                }
            },
            {
                path: 'ohmyfood',
                name: 'ohmyfood',
                component: OhMyFood,
                meta: {
                    title: "OhMyFood | Rida Benkou",
                    music: "project"
                }
            },
            {
                path: 'lachouetteagence',
                name: 'lachouetteagence',
                component: LaChouetteAgence,
                meta: {
                    title: "La Chouette Agence | Rida Benkou",
                    music: "project"
                }
            },
            {
                path: 'orinoco',
                name: 'orinoco',
                component: Orinoco,
                meta: {
                    title: "Orinoco | Rida Benkou",
                    music: "project"
                }
            },
            {
                path: 'sopekocko',
                name: 'sopekocko',
                component: SoPekocko,
                meta: {
                    title: "So Pekocko | Rida Benkou",
                    music: "project"
                }
            },
            {
                path: 'groupomania',
                name: 'groupomania',
                component: Groupomania,
                meta: {
                    title: "Groupomania | Rida Benkou",
                    music: "project"
                }
            }

        ]
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    }
})

export default router
