<template>
    <section id="paralaxcolumn" class="paralaxcolumn">

        <!-- Container -->
        <div :class="{paralaxcolumn_2: params.length == 2, paralaxcolumn_3: params.length == 3}" :style="`background-color: ${color};`" class="paralaxcolumn__media">
            
            <!-- Lot d'image -->
            <div v-for="item in params" :key="item" class="paralaxcolumn__media__item">
            <picture>
                <source :src="require(`@/assets/project/${item.image}.png`)" type="image/png">
                <img :height="item.height" :width="item.width" :src="require(`@/assets/project/${item.image}.webp`)">
            </picture>
               
            </div>

        </div>

    </section>
</template>

<script>
export default {
    name: "paralaxColumn",
    props: {
        params: {type: Array, required: true},
        color: {type: String, required: true},
        rotate: {type: Number, required: true},
    },
    methods:{

        //! Rétablit le CSS de base (anti-bug --> resize + GSAP)
        saveCSS(){
            return new Promise((resolve) => {
                const gsap = this.gsap
                gsap.set(".paralaxcolumn__media__item, .paralaxcolumn__media__item img", {clearProps: "all"})
                resolve()
            })
        },

        //! Toute la logique de l'effet au scroll
        scrollEffect(){

            const gsap = this.gsap
            const img = document.querySelectorAll(".paralaxcolumn__media__item img")
            const item = document.querySelectorAll(".paralaxcolumn__media__item")

            //- Effets translate paralax
            gsap.registerEffect({
                name: "paralax",
                effect: (targets, config) => {
                    return gsap.to(targets, {
                        scrollTrigger: {
                            trigger: ".paralaxcolumn__media",
                            start: "-50% center",
                            toggleActions: "play play reverse play",
                            end: `bottom top`,
                            scrub: 1
                        },
                        y: config.y,
                        x: config.x
                        
                    })
                }
            })

            //- Rotation
            gsap.set(item, {
                rotate: this.rotate,
            })

            for (let i = 0; i < img.length; i++) {

                //- Dimension
                gsap.set(item[i], {
                    width: `${this.params[i].dimension}%`
                })

                //- Fixation
                gsap.effects.paralax(item[i], {y: "0%", x: '-100%'})


                //- Direction et vitesse
                if(this.params[i].defilement == 'top'){
                    gsap.effects.paralax(img[i], {y: `${this.params[i].vitesse * 10}%`, x: '100%'})

                } else if(this.params[i].defilement == 'bottom'){
                    gsap.effects.paralax(img[i], {y: `-${this.params[i].vitesse * 10}%`, x: "100%"})
                }
        
            }

        },

        //! Toutes les méthodes à utiliser lors du montage
        allMethodsLoad(){
            this.saveCSS()
            .then(() => {
                this.scrollEffect()
            })
        }

    },
    mounted(){
        this.$nextTick(() => {
            this.allMethodsLoad()
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .paralaxcolumn_3{
        width: 120vw;
    }

    .paralaxcolumn_2{
        width: 110vw;
    }

    .paralaxcolumn{
        @include media-project;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        &__media{
            height: 120vh;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__item{
                transform-origin: center center;
            }

            img{
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }

    //! MEDIA QUERIES
    @media only screen and (max-width: 1400px) {
        .paralaxcolumn_3{
            width: 130vw;
        }

        .paralaxcolumn_2{
            width: 120vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .paralaxcolumn_3{
            width: 140vw;
        }

        .paralaxcolumn_2{
            width: 130vw;
        }
    }

    @media only screen and (max-width: 1000px) {
        .paralaxcolumn_3{
            width: 150vw;
        }

        .paralaxcolumn_2{
            width: 140vw;
        }
    }

    @media only screen and (max-width: 800px) {
        .paralaxcolumn_3{
            width: 180vw;
        }

        .paralaxcolumn_2{
            width: 150vw;
        }
    }

    @media only screen and (max-width: 700px) {
        .paralaxcolumn_3{
            width: 210vw;
        }

        .paralaxcolumn_2{
            width: 160vw;
        }

    }

    @media only screen and (max-width: 600px) {
        .paralaxcolumn_3{
            width: 245vw;
        }

        .paralaxcolumn_2{
            width: 170vw;
        }
    }

    @media only screen and (max-width: 500px) {
        .paralaxcolumn_3{
            width: 280vw;
        }

        .paralaxcolumn_2{
            width: 180vw;
        }
    }

    @media only screen and (max-width: 450px) {

        .paralaxcolumn_2{
            width: 200vw;
        }

        .paralaxcolumn_3{
            width: 290vw;
        }


    }

</style>