<template>
    <section :class="{entry_next: type == 'next', entry_current: type == 'current'}" v-relative id="entry" class="entry">
        
        <!-- Name -->
        <div class="entry__name">

            <!-- Index -->
            <span class="entry__name__index">{{index < 10 ? "0" + index : index}}</span>

            <!-- Nom -->
            <h1 class="entry__name__title">
                <span class="entry__name__title--1">{{name.trim().split(" ").length >= 3 ? name.split(" ").splice(0, 2).join(" ") : name}}</span>
                <span v-if="name.trim().split(' ').length >= 3" class="entry__name__title--2">{{name.split(" ").splice(2, name.split(" ").length).join(" ")}}</span>
            </h1>
            
        </div>

        <!-- Video -->
        <div class="entry__media">   
            <video preload="auto" playsinline loop muted autoplay  class="entry__media__item" :height="$store.state.listOfProject[index - 1].media.video.height" :width="$store.state.listOfProject[index - 1].media.video.width" :src="require(`@/assets/project/${$store.state.listOfProject[index - 1].media.video.source}.mp4`)">
                <!-- <source :src="require(`@/assets/project/${$store.state.listOfProject[index - 1].media.video.source}.webm`)" type="video/webm">
                <source :src="require(`@/assets/project/${$store.state.listOfProject[index - 1].media.video.source}.mp4`)"  type="video/mp4"> -->
            </video>
        </div>
        
        <div v-if="type == 'current'" class="entry__media--place">
            <img src="@/assets/project/videoplace.png" alt="">
        </div>
        

        <!-- Lien pour le projet suivant -->
        <router-link aria-label="Projet suivant" :to="{name: reference}" v-if="type == 'next'" name="cursorHoverProject" v-self class="entry__link" rel="nofollow"></router-link>

    </section>
</template>

<script>
import {ScrollTrigger} from 'gsap/ScrollTrigger'

export default {
    name: "Entry",
    props: ['name','index', 'reference', 'type'],
    inject: ["mobileActived"],
    methods:{

        //! Rétablit le CSS de base (anti-bug --> resize + GSAP)
        saveCSS(){

            return new Promise((resolve) => {
                const gsap = this.gsap
                gsap.set(".entry__media, .entry__media__item", {clearProps: "all"})
                resolve()
            })
           
        },

        //! Toute la logique de l'effet au scroll
        scrollEffect(){
            
            //* Si on est dans l'entrée en vidé du projet courant
            if(this.type == "current"){
                
                const gsap = this.gsap

                //: VALABLE SEULEMENT SUR "DESKTOP"
                if(this.mobileActived == false){

                    const media = document.getElementsByClassName("entry__media")

                    //- Petite rotation du media
                    const tween = gsap.fromTo(media, 
                        {
                            rotate: 3
                        },   
                        {
                            rotate: -3
                        }
                    )

                    ScrollTrigger.create({
                        animation: tween,
                        trigger: media,
                        start: `top center`,
                        end: "bottom top",
                        scrub: true
                    })

                

                //: VALABLE SEULEMENT SUR "MOBILE"
                } else {
                    
                    const media = document.querySelector(".entry_current .entry__media")
                    const video = document.querySelector(".entry_current .entry__media .entry__media__item")
                    const videoPlace = document.querySelector(".entry_current .entry__media--place")
                    const headerHeight = gsap.getProperty(".header", "height")
                    const videoHeight = gsap.getProperty(".entry_current .entry__media .entry__media__item", "height")
                
                    //- Grosse rotation du media
                    const tween = gsap.fromTo(media, 
                        {
                            rotate: 0
                        },   
                        {
                            rotate: 180
                        }
                    )

                    ScrollTrigger.create({
                        animation: tween,
                        trigger: ".header",
                        start: "bottom center",
                        end: `${videoHeight + headerHeight}px top`,
                        scrub: true
                    })

                    //- Fixation de la video
                    const tween2 = gsap.fromTo(video, 
                        {
                            rotate: 0
                        },   
                        {
                            rotate: -180
                        }
                    )

                    ScrollTrigger.create({
                        animation: tween2,
                        trigger: ".header",
                        start: "bottom center",
                        end: `${videoHeight + headerHeight}px top`,
                        scrub: true
                    })

                }

            }

        },

        //! Déterminer la taille du titre par rapport à la fenêtre
        defineFontSizeTitle(){

            return new Promise((resolve) => {

                var largeur

                if(this.mobileActived == false){
                    largeur = 0.6
                } else {
                    largeur = 0.7
                }

                const gsap = this.gsap

                const title = document.querySelectorAll(".entry__name__title--1")
                const title2 = document.querySelectorAll(".entry__name__title--2")

                for (let i = 0; i < title.length; i++) {

                    const titleWidth = title[i].offsetWidth
                    const titleFontSize = gsap.getProperty(title[i], "fontSize")    
                    const titleWidthWill = window.innerWidth * largeur

                    const mulFontSize = titleWidthWill / titleWidth
                    const titleFontSizeWill = mulFontSize * titleFontSize

                    title[i].style.fontSize = `${titleFontSizeWill}px`

                    if(title2[i] !== undefined){
                        title2[i].style.fontSize = `${titleFontSizeWill}px`
                    }
                    
                }
          
                resolve()

            })           
        },

        //! Déterminer la taille de l'index par rapport à la fenêtre
        defineFontSizeIndex(){

            const gsap = this.gsap

            var largeur 

            if(this.mobileActived == false){
                largeur = 0.033
            } else {
                largeur = 0.04
            }

            const index = document.getElementsByClassName("entry__name__index")

            for (let i = 0; i < index.length; i++) {

                const indexWidth = index[i].offsetWidth
                const indexFontSize = gsap.getProperty(index[i], "fontSize")    
                const indexWidthWill = window.innerWidth * largeur

                const mulFontSize = indexWidthWill / indexWidth
                const indexFontSizeWill = mulFontSize * indexFontSize

                index[i].style.fontSize = `${indexFontSizeWill}px`
                
            }
            
        },

        //! Définir la marge négative de la vidéo
        defineTopVideo(){

            const gsap = this.gsap

            var type
            if(this.type == "next"){
                type = ".entry_next"
            } else {
                type = ".entry_current"
            }

            const title = document.querySelector(`${type} .entry__name`)
            const titleMain = document.querySelector(`${type} .entry__name__title--1`)
            const titleSecond = document.querySelector(`${type} .entry__name__title--2`)
            const video = document.querySelector(`${type} .entry__media`)
            const videoPlace = document.querySelector(`${type} .entry__media--place`)
            const titleHeightMain = gsap.getProperty(titleMain, "height")
            const titleHeightSecond = gsap.getProperty(titleSecond, "height") ?? 0

            if(this.type == 'current'){

                title.style.marginTop = `-${(titleHeightMain) * 0.6}px`

                if(titleHeightSecond == 0){
                    videoPlace.style.marginTop = `-${(titleHeightMain) * 0.4}px`
                } else {
                    videoPlace.style.marginTop = `-${(titleHeightMain + titleHeightSecond) * (1 - (1 / 2) * 0.6)}px`
                }

            } else {

                if(titleHeightSecond == 0){
                    video.style.marginTop = `-${(titleHeightMain) * 0.4}px`
                } else {
                    video.style.marginTop = `-${(titleHeightMain + titleHeightSecond) * (1 - (1 / 2) * 0.6)}px`
                }
                
            }
            
            
        },

        //! Déterminer le dépassement du "EntryNext"
        overflowEntryNext(){

            const windowHeightPreviousElement = this.gsap.getProperty(".header", "height")
            const entryNext = document.getElementsByClassName("entry_next")[0]
            const entryNextHeight = this.gsap.getProperty(entryNext, "height")
            const entryNextMarginBottom = window.innerHeight - windowHeightPreviousElement - entryNextHeight

            entryNext.style.marginBottom = `${entryNextMarginBottom}px`
            
        },

        //! Toutes les méthodes à utiliser lors du montage
        allMethodsLoad(){

            this.saveCSS()
            .then(() => {

                this.defineFontSizeTitle()
                .then(() => {
                    this.defineFontSizeIndex()
                })
                .then(() => {
                    this.defineTopVideo()
                })
                .then(() => {
                    setTimeout(() =>{
                        if(this.type == 'current'){
                            this.scrollEffect()
                        }
                    }, 1000 * this.$store.state.transition.project.duration.video)
                })
                .then(() => {

                    if(this.type == 'next'){
                        this.overflowEntryNext()
                    }

                })

            })
       
        }

    },
    mounted(){
        this.$nextTick(() => {
            this.allMethodsLoad()
        })
    },
}
</script>

<style lang="scss">

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .entry_current{
        padding-top: 60vh;

        .entry__media{
            top: 60vh!important;
            position: absolute;
            top: 0px;
            bottom: 0px;
            width: calc(100% - (mediaClamp(40, 80, 1px, 1000) * 2))
        }
    }

    .entry{
        @include padding-project();
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        background-color: var(--primary-color);

        &__name{
            z-index: 2;
            mix-blend-mode: exclusion;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            position: relative;

            *{
                color: var(--primary-color);
            }

            &__title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span{
                    font-size: 10px;
                    font-weight: 100;
                    font-family: $primary-font;
                }
                
            }

            &__index{
                position: absolute;
                left: 0px;
                top: 0px;
                transform: translate(-150%, 25%);
                font-size: rem(50px);
                margin-right: 10px;
            }
        
        }

        &__media, &__media--place{
            transform: rotate(3deg);
            width: 100%;
            clip-path: inset(1px 1px);
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            aspect-ratio: 16 / 9;
        }

        &__media{

            &__item{
                clip-path: inset(1px 1px);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: cover;
                object-position: center;
            }
        }

        &__media--place{
            clip-path: inset(1px 1px);
            visibility: hidden;
            pointer-events: none;
            
                img{
                    border: 1px solid white;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: cover;
                    object-position: center;
                    opacity: 1;
                }
            
        }

    }

    //! MEDIA QUERIES  
    @media only screen and (max-width: $mobile-big-change) {

        .entry__media, .entry__media--place{
            transform: rotate(0deg);
        }
        
        .entry_current{
            padding-left: 0;
            padding-right: 0;
        }

        .entry__media, .entry__media__item, .entry__media--place, .entry__media--place img{
            height: 80vh;
            width: 105vw!important;
            object-fit: cover;
            object-position: center center;
            aspect-ratio: initial;
        }

    }

</style>