<template>
    <section id="reservia" class="reservia">

        <div class="header">
            <InfoProject
            :type="'current'"
            :mission="mission"
            :techno="techno"
            :author="author"
            :date="date"
            />
        </div>

        <EntryVideo
        ref="entryvideocurrent"
        :name="name"
        :index="index"
        :type="'current'"
        />

        <Details
        :title="'Presentation'"
        :text="presentation"
        />

        <ParalaxColumn
        ref="paralaxcolumn"
        :params="[
            {
                image: 'reservia/paralax/activite',
                height: 3532,
                width: 650,
                dimension: 28,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple d\'activité chez Reservia'
            },
            {
                image: 'reservia/paralax/hebergement',
                height: 3532,
                width: 650,
                dimension: 31,
                defilement: 'top',
                vitesse: 2,
                alt: 'Exemple d\'hébergement chez Reservia'
            },
            {
                image: 'reservia/paralax/popular',
                height: 3532,
                width: 650,
                dimension: 28,
                defilement: 'bottom',
                vitesse: 2,
                alt: 'Exemple d\'hébergement populaire chez Reservia'
            }
        ]"
        :rotate="25"
        :color="mainColor"
        />

        <Details
        :title="'Contexte'"
        :text="'Reservia voulait créer un site permettant aux usagers de trouver des hébergements et des activités dans la ville de leur choix. Les hébergements devaient également pouvoir être filtrés par thématique, par exemple leur budget ou leur ambiance.'"
        />

        <ParalaxOne
        ref="paralaxone"
        :index="index"
        :backImage="'reservia/image/logo.svg'"
        :color="mainColor"
        />

        <Details
        :title="'Mission'"
        :text="`Ma mission consistait à intégrer les maquettes desktop et mobile fournit en adaptant le site aux résolutions mobiles, tablettes et bureau. 
        J'ai aussi édité plusieurs tailles d'images qui répondent à des tranches de résolution particulières afin d\'optimiser les performances.`"
        />

        <MediaComment
        :params="{
            media: 'reservia/image/interact',
            height: 750,
            width: 1080
        }"
        :color="mainColor"
        :direction="'left'"
        :title="'Effets d\'intéraction'"
        :text="`Il m'avait été demandé pour cette mission de rendre cliquables les cartes d\'hébergement et d\'activités et d'intégrer un effet au survol sur chacune d\'entre elles.`"
        />

        <Results 
        :results="results"
        />

        <EntryVideo
        class="spook"
        ref="entryvideonext"
        :type="'next'"
        :name="next.name"
        :index="next.index"
        :reference="next.reference"
        />

        <CursurCustom ref="cursorcustom" v-if="mobileActived == false"/>
        <ResizeWindow @reload="forceUpdate"/>

    </section>
</template>

<script>
import CursurCustom from '@/components/CursorCustom.vue'
import ResizeWindow from '@/components/ResizeWindow.vue'
import EntryVideo from '@/components/EntryVideo.vue'
import ParalaxColumn from '@/components/ParalaxColumn.vue'
import Details from '@/components/DetailsProject.vue'
import ParalaxOne from '@/components/ParalaxOne.vue'
import MediaComment from '@/components/MediaComment.vue'
import Results from '@/components/ResultsProject.vue'
import InfoProject from '@/components/InfoProject.vue'

export default {
    name: "Reservia",
    inject: ["mobileActived"],
    data() {
        return{
            name: undefined,
            index: undefined,
            mission: undefined,
            techno: undefined,
            author: undefined,
            date: undefined,
            mainColor: undefined,
            presentation: undefined,
            results: undefined,
            next: {
                name: undefined,
                index: undefined,
                reference: undefined
            }
        }   
    },
    components: {
        EntryVideo, ParalaxColumn, Details, ParalaxOne, MediaComment, Results, InfoProject, CursurCustom, ResizeWindow
    },
    methods: {

        //! Fonction qui relance les fonctions de montage de chaque composant
        forceUpdate(){

            this.$emit("forceupdate", true)

            scrollTo(0, 0)

            this.$store.dispatch("deleteAllGSAP", {
                clearScrollTrigger: true,
                clearMatchMedia: true,
                clearScrollMemory: true,
                clearAnimation: true
            })
            .then(() => {
                this.$nextTick(() => {

                    if(this.mobileActived == false){
                        this.$refs.cursorcustom.allMethodsLoad()
                    } 

                    this.$refs.entryvideocurrent.allMethodsLoad()
                    this.$refs.paralaxcolumn.allMethodsLoad()
                    this.$refs.paralaxone.allMethodsLoad()
                    this.$refs.entryvideonext.allMethodsLoad()

                })
            })

        },

        //! Chercher le projet dans le store et amener les bonnes informations
        searchProjectStore(){

            const project = this.$route.name
            const index = this.$store.state.listOfProject.findIndex((value) => value.ref == project)
        
            const projectList = this.$store.state.listOfProject[index]
            this.name = projectList.name
            this.index = index + 1
            this.mission = projectList.mission
            this.techno = projectList.techno
            this.author = projectList.author
            this.date = projectList.date
            this.mainColor = projectList.color
            this.presentation = projectList.presentation
            this.results = projectList.results

            var projectNext, indexNext

            if(this.$store.state.listOfProject[index + 1] !== undefined){
                projectNext = this.$store.state.listOfProject[index + 1]
                indexNext = index + 2
            } else {
                projectNext = this.$store.state.listOfProject[0]
                indexNext = 1
            }
          
            this.next.name = projectNext.name
            this.next.index = indexNext
            this.next.reference = projectNext.ref

        }
    },
    beforeMount(){
        this.searchProjectStore()
    },
    mounted(){
        scrollTo(0, 0)
        this.$store.dispatch("spookAnimation")
    },
    beforeUnmount(){
        this.$store.dispatch("deleteAllGSAP", {
            clearScrollTrigger: true,
            clearMatchMedia: true,
            clearScrollMemory: true,
            clearAnimation: true
        })
    }
}
</script>

<style lang="scss" scoped>

    @import '@/sass/utils/variable.scss';
    @import '@/sass/utils/function.scss';
    @import '@/sass/utils/mixins.scss';

    .header{
        @include header-project();
    }

</style>